<template>
    <div>
        <b-row class="panel-title"
            v-if="ProfileUnlockRequestDetail.EmailInstrucionsbyError">
            <label :class="col-lg-6" style="color:#4d5161 !important;"> INSTRUCTIONS</label>
        </b-row>
        <b-row class="panel-content row"
            v-if="ProfileUnlockRequestDetail.EmailInstrucionsbyError && ProfileUnlockRequestDetail.EmailInstrucionsbyError!='Network-Affidavit time is set cannot chance Actual Cost' && ProfileUnlockRequestDetail.EmailInstrucionsbyError!='Buy is Reconciled'">
            <b-col cols="lg-9">
                <div>{{ ProfileUnlockRequestDetail.EmailInstrucionsbyError }} </div>
            </b-col>
        </b-row>
        <b-row
            v-if="ProfileUnlockRequestDetail.EmailInstrucionsbyError && ProfileUnlockRequestDetail.EmailInstrucionsbyError=='Network-Affidavit time is set cannot chance Actual Cost'"
            class="panel-content row">
            <b-col cols="lg-12">
                <table>
                    <tr>
                        <td>
                            <p
                                style="font-family: 'Open Sans'; font-size: 13px; font-weight: normal; font-stretch: normal; font-style: normal; line-height: 1.54; letter-spacing: normal; text-align: left; color: #4d5161; background-color: #ffffff; text-transform:lowercase;">
                                If you add Affid Date and Affid Time to your column layout, you should be able to then
                                delete the date/time for the units that you need to change. Once you remove the
                                Affidavit date/time, you should be able to make the changes.</p>
                            <p
                                style="font-family: 'Open Sans'; font-size: 13px; font-weight: normal; font-stretch: normal; font-style: normal; line-height: 1.54; letter-spacing: normal; text-align: left; color: #4d5161; background-color: #ffffff;text-transform:lowercase;">
                                With this error, Affidavit Time is set; cannot change actual costin order to make the
                                changes to the units you will have to update them in National Toolkit-NBUY.First, you
                                will have to remove the affid time from this unit.</p>
                            <p
                                style="font-family: 'Open Sans'; font-size: 13px; font-stretch: normal; font-style: normal; line-height: 1.54; letter-spacing: normal; text-align: center; color: #4d5161; background-color: #ffffff; padding-top: 40px; text-align:left;">
                                <strong> STEPS: </strong></p>
                            <table>
                                <tr>
                                    <td
                                        style="font-family: 'Open Sans'; font-size: 13px; font-stretch: normal; font-style: normal; line-height: 1.54; letter-spacing: normal; text-align: center; color: #4d5161; background-color: #ffffff; padding-top: 10px; text-align:left;">
                                        1. Pull up the unit in NBUY.
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style="font-family: 'Open Sans'; font-size: 13px; font-stretch: normal; font-style: normal; line-height: 1.54; letter-spacing: normal; text-align: center; color: #4d5161; background-color: #ffffff; padding-top: 10px; text-align: left;">
                                        2. In Action enter DM,AFFID, hit enter.
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style="font-family: 'Open Sans'; font-size: 13px; font-stretch: normal; font-style: normal; line-height: 1.54; letter-spacing: normal; text-align: center; color: #4d5161; background-color: #ffffff; padding-top: 10px; text-align: left;">
                                        3. Change Action to CM,AFFID, hit enter.
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style="font-family: 'Open Sans'; font-size: 13px; font-stretch: normal; font-style: normal; line-height: 1.54; letter-spacing: normal; text-align: center; color: #4d5161; background-color: #ffffff; padding-top: 10px; text-align: left;">
                                        4. Place an asterisk (*) in the field you want to remove the time (remember the
                                        affid time.)
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style="font-family: 'Open Sans'; font-size: 13px; font-stretch: normal; font-style: normal; line-height: 1.54; letter-spacing: normal; text-align: center; color: #4d5161; background-color: #ffffff; padding-top: 10px; text-align: left;">
                                        5. Hit enter. The affid time will be removed.
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style="font-family: 'Open Sans'; font-size: 13px; font-stretch: normal; font-style: normal; line-height: 1.54; letter-spacing: normal; text-align: center; color: #4d5161; background-color: #ffffff; padding-top: 10px; text-align: left;">
                                        6. Change Action to D,unit date to display one unit at a time to make changes
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style="font-family: 'Open Sans'; font-size: 13px; font-stretch: normal; font-style: normal; line-height: 1.54; letter-spacing: normal; text-align: center; color: #4d5161; background-color: #ffffff; padding-top: 10px; text-align: left;">
                                        7. Change Action to C, change to the Actual $ field and hit enter.
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style="font-family: 'Open Sans'; font-size: 13px; font-stretch: normal; font-style: normal; line-height: 1.54; letter-spacing: normal; text-align: center; color: #4d5161; background-color: #ffffff; padding-top: 10px; text-align: left;">
                                        8. Change Action to CM,AFFID.
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style="font-family: 'Open Sans'; font-size: 13px; font-stretch: normal; font-style: normal; line-height: 1.54; letter-spacing: normal; text-align: center; color: #4d5161; background-color: #ffffff; padding-top: 10px; text-align: left;">
                                        9. Re-enter Affid time and hit enter to finish editing the unit and return the
                                        affid time.
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style="font-family: 'Open Sans'; font-size: 13px; font-stretch: normal; font-style: normal; line-height: 1.54; letter-spacing: normal; text-align: center; color: #4d5161; background-color: #ffffff; padding-top: 10px; text-align: left;">
                                        10. Refresh the system.
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </b-col>
        </b-row>
        <b-row
            v-if="ProfileUnlockRequestDetail.EmailInstrucionsbyError && ProfileUnlockRequestDetail.EmailInstrucionsbyError=='Buy is Reconciled'"
            class="panel-content row">
            <b-col cols="lg-12">
                <table>
                    <tr>
                        <td>
                            <p
                                style="font-family: 'Open Sans'; font-size: 13px; font-weight: normal; font-stretch: normal; font-style: normal; line-height: 1.54; letter-spacing: normal; text-align: left; color: #4d5161; background-color: #ffffff;text-transform: none;">
                                Team needs to email prismabuyunlock@phdmedia.com for assistance after the buy is
                                unlocked.</p>
                            <p
                                style="font-family: 'Open Sans'; font-size: 13px; font-weight: normal; font-stretch: normal; font-style: normal; line-height: 1.54; letter-spacing: normal; text-align: left; color: #4d5161; background-color: #ffffff;text-transform: none;">
                                Once a placement is reconciled with an invoice it is permanently locked and cannot be
                                changed even if you change the BY profile. The BY profile change would allow you to add
                                a new invoice or credit memo against that reconciled invoice and reconcile that
                                successfully.</p>
                            <p
                                style="font-family: 'Open Sans'; font-size: 13px; font-weight: normal; font-stretch: normal; font-style: normal; line-height: 1.54; letter-spacing: normal; text-align: center; color: #4d5161; background-color: #ffffff; text-align:left;text-transform: none;">
                                 If the Buy is Actualized and has a Reconciled and Cleared Invoice:
                            </p>
                            <table>
                                <tr>
                                    <td
                                        style="font-family: 'Open Sans'; font-size: 13px; font-stretch: normal; font-style: normal; line-height: 1.54; letter-spacing: normal; text-align: center; color: #4d5161; background-color: #ffffff; text-align:left;">
                                        a. The current workflow does not allow any user to reverse a cleared invoice.
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style="font-family: 'Open Sans'; font-size: 13px; font-stretch: normal; font-style: normal; line-height: 1.54; letter-spacing: normal; text-align: center; color: #4d5161; background-color: #ffffff; text-align: left;">
                                        b. The new process needs to take place where the specified team per agency
                                        inputs one of the following:                            
                                    </td>
                                </tr>  
                                <tr>
                                    <td
                                        style="font-family: 'Open Sans'; font-size: 13px; font-stretch: normal; font-style: normal; line-height: 1.54; letter-spacing: normal; text-align: center; color: #4d5161; background-color: #ffffff; text-align: left;">
                                        i. Credit Memo – Apply a Credit Memo to create a clearance for a negative amount.                     
                                    </td>
                                </tr>  
                                <tr>
                                    <td
                                        style="font-family: 'Open Sans'; font-size: 13px; font-stretch: normal; font-style: normal; line-height: 1.54; letter-spacing: normal; text-align: center; color: #4d5161; background-color: #ffffff; text-align: left;">
                                        ii. Pending Adjustment – Apply a Pending Adjustment to add an additional amount to the Invoice.         
                                    </td>
                                </tr>                  
                            </table>
                        </td>
                    </tr>
                </table>
            </b-col>
        </b-row>
    </div>
</template>

<style scope>
    .panel-list:not(.media-dropdowns) {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }

    .panel-list .panel-body .panel-title {
        border-bottom: 1px solid rgba(151, 151, 151, 0.2);
        width: 100%;
        margin-left: 0px;
        margin-bottom: 25px !important;
    }

        .panel-list .panel-body .panel-title label {
            color: #77a2bb;
            margin-bottom: 0px !important;
            padding-top: 13px !important;
            font-family: "Open Sans";
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.57;
            letter-spacing: normal;
            padding-left: 0px !important;
            min-height: 35px;
        }

        .panel-list .panel-body .panel-title .btn {
            padding-left: 5px;
            padding-right: 5px;
        }

        .panel-list .panel-body .panel-title > div {
            padding-right: 0px !important;
            float: right;
            font-family: "Open Sans";
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.75;
            letter-spacing: normal;
            color: #00a1d2;
        }

    .panel-list .panel-content label {
        text-transform: uppercase;
        color: #2c3865;
        font-size: 12px;
        margin-bottom: 1px !important;
        padding-left: 0px;
    }

    .panel-list .panel-content p {
        text-transform: uppercase;
        color: #4d5161;
        font-size: 14px;
        font-weight: 600;
    }

    .panel-content.row > div > label:first-child {
        color: #a2a9ad !important;
        font-family: "Open Sans" !important;
        font-size: 10px;
        font-weight: 600 !important;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
    }

    .panel-content.row > div > *:not(:first-child) {
        font-family: "Open Sans" !important;
        font-size: 14px !important;
    }

    .panel-content.row > div > div {
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #4d5161;
    }

    .panel-list > .panel-body {
        margin-top: 1rem !important;
    }

    .panel-content.row > div {
        margin-bottom: 10px !important;
    }
</style>

<script>
    export default {
        data() {
            return {
            };
        },
        props: {
            ProfileUnlockRequestDetail: {
                required: true,
                type: Object,
            }
        },
    }
</script>
